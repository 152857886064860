import { FC } from 'react'
import { SvgProps } from './svgProps'

const SearchIconNew: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '15'}
    height={height || '15'}
    viewBox="0 0 15 15"
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_1_643)">
      <path
        d="M6.58853 11.5558C9.70731 11.5558 12.2356 9.16801 12.2356 6.22249C12.2356 3.27697 9.70731 0.88916 6.58853 0.88916C3.46974 0.88916 0.941467 3.27697 0.941467 6.22249C0.941467 9.16801 3.46974 11.5558 6.58853 11.5558Z"
        stroke={color || '#757575'}
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M13.8829 13.6667L10.5168 10.4883"
        stroke={color || '#757575'}
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_643">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SearchIconNew
