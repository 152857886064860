import { FC } from 'react'
import { SvgProps } from './svgProps'

const ClearIcon: FC<SvgProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 17}
    height={height || 17}
    viewBox="0 0 15 15"
    fill="none"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill="#C6C6C6" />
    <path d="M4.28613 4.36304L10.7954 10.7175" stroke="#EEEEEE" />
    <path d="M4.29785 10.5107L10.7627 4.33476" stroke="#EEEEEE" />
  </svg>
)

export default ClearIcon
