import { FC, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileHeaderButton from '@/components/molecules/mobileHeaderButton'
import { validFor } from '@/theme/validFor'
import {
  ToolbarProps,
  TabletOrMobileToolbarProps
} from '@/components/organisms/toolbar/toolbar'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { ITheme } from '@/theme/interfaces/ITheme'
import { Routes } from '@/core/routes'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import LoginButton from '@/components/molecules/loginButton'
import { useOrder } from '@/providers/order/old/orderProvider'
import { ReactHeight } from 'react-height'
import { WishlistIconDefault } from '@/components/atoms/icons/WishlistIconDefault'
import SwiperMenuAdd from '../../swiperMenuAdd'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import SlideMenuAdditionalInfo from '../../slideMenuAdditionalInfo'
import { Asset, useAsset } from '@/providers/assetsProvider'
import { ChristmasGiftBoxIconDefault } from '@/components/atoms/icons/ChristmasGiftBoxIconDefault'
import { useScrollCondition } from '../../../../hooks/useScroll'
import { CartIconNewDefault } from '@/components/atoms/icons/CartIconNewDefault'
import ProductSearchNew from '@/components/molecules/productSearchNew'
import { IconButton } from '@/components/molecules/iconButtons'
import { MenuDrawerDefault } from '@/components/atoms/icons/MenuDrawerDefault'
import useDevice from 'hooks/useDevice'

type DesktopToolbarProps = ToolbarProps &
  TabletOrMobileToolbarProps & { theme?: ITheme }

const DesktopToolbarWrapper: FC<DesktopToolbarProps> = ({
  showHeaderPromo,
  headerNotice,
  isSearchbarOpen,
  ...rest
}) => {
  const [toolbarHeight, setToolbarHeight] = useState<number>(0)
  const offset = 50
  const isSticky = useScrollCondition((scroll) => scroll > offset)
  const isHeaderNoticeEnabled = headerNotice?.text.length > 0

  return (
    <Wrapper toolbarHeight={toolbarHeight} isSticky={isSticky}>
      <ReactHeight onHeightReady={(height) => setToolbarHeight(height)}>
        <DesktopToolbarNew
          {...rest}
          showHeaderPromo={showHeaderPromo}
          headerNotice={headerNotice}
          isHeaderNoticeEnabled={isHeaderNoticeEnabled}
          isSearchbarOpen={isSearchbarOpen}
        />
      </ReactHeight>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  height: ${(props) => props.toolbarHeight}px;
  position: relative;
  z-index: 12;
`

const DesktopToolbarNew: FC<DesktopToolbarProps> = ({
  theme,
  onDrawerClick,
  wishlistProductCount
}) => {
  const { getFocusType } = useCurrentPage()
  const { count } = useOrder()
  const display = getFocusType() === FocusMode.Basic ? 'none' : 'inherit'
  const { show, showAdditionalMenu, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()
  const { isChristmasPromoTime, isShutDownCountry, isCartProcessStep } =
    useAsset()

  const isSearchbarOpen = !isCartProcessStep
  const { isTablet } = useDevice()
  return (
    <StyledFlex height="90px" isSearchbarOpen={isSearchbarOpen}>
      <Flex flexDirection="row">
        {isTablet && (
          <StyledIconButton
            onClick={onDrawerClick}
            title={''}
            IconElement={
              <MenuDrawerDefault
                color={theme.colors.onBackground}
                data-cy="mobileHeaderToolbar"
              />
            }
            display={display}
          />
        )}

        <ToolbarLinkLogo
          imgAsset={Asset.InvertedLogo}
          ml={display ? 0 : 20}
          data-cy="headerMobileLogo"
        />
      </Flex>

      {!isShutDownCountry && (
        <SearchWrapper isCartProcessStep={isCartProcessStep}>
          <ProductSearchNew isSearchbarOpen={isSearchbarOpen} />
        </SearchWrapper>
      )}

      <Flex
        flexDirection="row"
        alignItems="center"
        height={29}
        data-cy="mobileHeaderButtons"
      >
        <LoginButton newSearch={true} color={theme.colors.onBackground} />
        {!isShutDownCountry && (
          <>
            <MobileHeaderButton
              data-cy={Routes.wishlist}
              hrefLink={Routes.wishlist}
              IconElement={
                isChristmasPromoTime ? (
                  <ChristmasGiftBoxIconDefault
                    color={theme.colors.onBackground}
                  />
                ) : (
                  <WishlistIconDefault color={theme.colors.onBackground} />
                )
              }
              notifyCount={wishlistProductCount}
              display={display}
            />

            <MobileHeaderButton
              data-cy={Routes.cart}
              hrefLink={Routes.cart}
              IconElement={
                <CartIconNewDefault color={theme.colors.onBackground} />
              }
              notifyCount={count}
            />
            {show && <SlideMenuAdditionalInfo />}
            {showAdditionalMenu || showAdditionalMenuOnHP ? (
              <SwiperMenuAdd color={theme.colors.onBackground} />
            ) : null}
          </>
        )}
      </Flex>
    </StyledFlex>
  )
}

export default withTheme(DesktopToolbarWrapper)

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.theme.colors.menuBackground};
  flex-direction: row;
  justify-content: space-between;
  ${validFor.tablet` 
    background-color: ${(props) => props.theme.colors.background};
  `}
  align-items: center;
  transition: all 0.2s ease-out;
`

const SearchWrapper = styled.div`
  justify-content: stretch;

  ${validFor.desktop` 
    min-width: 500px;
  `}

  ${validFor.tablet` 
    min-width: 400px;
    max-width: 450px;
  `}
  display: ${(props) => (props.isCartProcessStep ? 'none' : 'flex')};
`
const StyledIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.colors.background};
`
