import {
  Desktop,
  DesktopOrTablet,
  MediaContextProvider,
  Mobile,
  Tablet
} from '@/components/atoms/media'
import { FC } from 'react'
import DesktopToolbar from '@/components/organisms/toolbar/desktop/desktopToolbar'
import MobileToolbar from '@/components/organisms/toolbar/mobile/mobileToolbar'
import { useWishlist } from '@/providers/wishlistProvider'
import { HeaderNotice } from '@/network/graphql.g'
import MobileToolbarNew from './mobile/mobileToolbarNew'
import DesktopToolbarNew from './desktop/desktopToolbarNew'
import TabletToolbar from './tabletToolbar'
import { useAsset } from '@/providers/assetsProvider'

export type ToolbarProps = {
  onDrawerClick?: () => void
  showHeaderPromo: boolean
  isSticky?: boolean
  headerNotice?: HeaderNotice
  isHeaderNoticeEnabled?: boolean
  isSearchbarOpen?: boolean
}

export type TabletOrMobileToolbarProps = {
  wishlistProductCount: number
  showHeaderPromo: boolean
}

const Toolbar: FC<ToolbarProps> = ({
  onDrawerClick,
  showHeaderPromo,
  headerNotice,
  isSearchbarOpen
}) => {
  const { wishlistProductCount } = useWishlist()

  const { isNewSearch } = useAsset()

  return (
    <MediaContextProvider>
      <DesktopOrTablet>
        {isNewSearch ? (
          <DesktopToolbarNew
            onDrawerClick={onDrawerClick}
            wishlistProductCount={wishlistProductCount}
            showHeaderPromo={showHeaderPromo}
            headerNotice={headerNotice}
            isSearchbarOpen={isSearchbarOpen}
          />
        ) : (
          <>
            <Desktop>
              <DesktopToolbar wishlistProductCount={wishlistProductCount} />
            </Desktop>
            <Tablet>
              <TabletToolbar
                onDrawerClick={onDrawerClick}
                wishlistProductCount={wishlistProductCount}
              />
            </Tablet>
          </>
        )}
      </DesktopOrTablet>

      <Mobile>
        {isNewSearch ? (
          <MobileToolbarNew
            onDrawerClick={onDrawerClick}
            wishlistProductCount={wishlistProductCount}
            showHeaderPromo={showHeaderPromo}
            headerNotice={headerNotice}
            isSearchbarOpen={isSearchbarOpen}
          />
        ) : (
          <MobileToolbar
            onDrawerClick={onDrawerClick}
            wishlistProductCount={wishlistProductCount}
            showHeaderPromo={showHeaderPromo}
            headerNotice={headerNotice}
            isSearchbarOpen={isSearchbarOpen}
          />
        )}
      </Mobile>
    </MediaContextProvider>
  )
}

export default Toolbar
