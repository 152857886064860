import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import { SearchIconNewDefault } from '../atoms/icons/SearchIconDefaultNew'
import { ClearIconDefault } from '../atoms/icons/ClearIconDefault'

type ProductSearchNewProps = {
  isSearchbarOpen?: boolean
  isMobile?: boolean
} & Rest

const ProductSearchNew: FC<ProductSearchNewProps> = ({
  isSearchbarOpen,
  isMobile,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const router = useRouter()
  const { t } = useTranslation('common')

  const [query, setQuery] = useState<string>('')

  useEffect(() => {
    if (inputRef.current) {
      const searchQuery = router.query?.search
      inputRef.current.value = Array.isArray(searchQuery)
        ? searchQuery[0]
        : searchQuery || ''
    }
  }, [router.query?.search])

  const submitSearch = (e: React.FormEvent) => {
    e.preventDefault()

    if (!inputRef.current?.value) {
      inputRef.current?.focus()
      return
    }

    router.push({
      pathname: Routes.search,
      query: { search: inputRef.current.value }
    })

    setQuery('')
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.blur()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const clearInput = () => {
    setQuery('')
    inputRef.current?.focus()
  }

  return (
    <StyledForm onSubmit={submitSearch} key={router.asPath}>
      <button type="submit" style={{ display: 'none' }} aria-hidden="true" />
      <StyledContainer>
        <StyledSearchWrapper isMobile={isMobile} {...rest}>
          <StyledSearchButtonNew
            type="button"
            onClick={() => inputRef.current?.focus()}
          >
            <SearchIconNewDefault />
          </StyledSearchButtonNew>
          <StyledSearchInputNew
            ref={inputRef}
            id="autocompleteInput"
            type="text"
            name="ProductSearchNew"
            placeholder={t('Toolbar.Search.inputPlaceholderNew')}
            data-cy="headerInputSearch"
            className="fulltext-autocomplete"
            isSearchbarOpen={isSearchbarOpen}
            value={query}
            autoComplete="off"
            onChange={handleInputChange}
          />
          {query && (
            <StyledClearButton type="button" onClick={clearInput}>
              <ClearIconDefault />
            </StyledClearButton>
          )}
        </StyledSearchWrapper>
      </StyledContainer>
    </StyledForm>
  )
}

export default ProductSearchNew

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledSearchWrapper = styled.div<{
  isSearchbarOpen?: boolean
  isMobile?: boolean
}>`
  z-index: 1001;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isSearchbarOpen ? 'white' : '#eeeeee')};
  border-radius: 10px;
  padding: 5px 0 5px 15px;
  min-width: 0;
  flex-grow: 1;
  width: 100%;

  ${({ isMobile }) => (isMobile ? `margin:  0 15px;` : '')}
`

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`

const StyledClearButton = styled.div`
  width: 36px;
  border-radius: 50%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`

const StyledSearchInputNew = styled.input`
  flex-grow: 1;
  border: none !important;
  background: transparent;
  outline: none;
  padding: 8px;
  font-size: 12px;
  ${(props) => props.theme.headerSearchInput && props.theme.headerSearchInput}

  &::placeholder {
    color: #757575;
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.searchInputColor};
  }
  &:focus {
    outline-style: none;
    border: ${(props) => props.isSearchbarOpen && 'none'};
  }
`

const StyledSearchButtonNew = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  & svg {
    fill: ${(props) => props.theme.colors.searchButton};
    width: 20px;
    height: 20px;
  }
  &:hover svg {
    fill: ${(props) => props.theme.colors.searchInputColor};
  }
`
