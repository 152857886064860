import { FC, useContext, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileHeaderButton from '@/components/molecules/mobileHeaderButton'
import {
  IconButton,
  IconButtonCapitalized
} from '@/components/molecules/iconButtons'
import { validFor } from '@/theme/validFor'
import {
  ToolbarProps,
  TabletOrMobileToolbarProps
} from '@/components/organisms/toolbar/toolbar'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { ITheme } from '@/theme/interfaces/ITheme'
import { Routes } from '@/core/routes'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import LoginButton from '@/components/molecules/loginButton'
import { useOrder } from '@/providers/order/old/orderProvider'
import { ReactHeight } from 'react-height'
import { MenuDrawerDefault } from '@/components/atoms/icons/MenuDrawerDefault'
import { SearchIconDefault } from '@/components/atoms/icons/SearchIconDefault'
import { WishlistIconDefault } from '@/components/atoms/icons/WishlistIconDefault'
import { CartIconDefault } from '@/components/atoms/icons/CartIconDefault'
import ProductSearchPopUpWrapper from '@/components/molecules/productSearch/productSearchPopUpWrapper'
import { useLuigiTracker } from '@/providers/luigiTrackerProvider'
import ProductSearch from '@/components/molecules/productSearch'
import SwiperMenuAdd from '../../swiperMenuAdd'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import SlideMenuAdditionalInfo from '../../slideMenuAdditionalInfo'
import { useAsset } from '@/providers/assetsProvider'
import { ChristmasGiftBoxIconDefault } from '@/components/atoms/icons/ChristmasGiftBoxIconDefault'
import { MarketCode } from '@/network/graphql.g'
import { StoreContext, useDomain } from '@/providers/storeProvider'
import { useScrollCondition } from '../../../../hooks/useScroll'
import { useTowerCheckout } from '@/providers/experimentProvider'

type MobileToolbarProps = ToolbarProps &
  TabletOrMobileToolbarProps & { theme?: ITheme }

const MobileToolbarWrapper: FC<MobileToolbarProps> = ({
  showHeaderPromo,
  headerNotice,
  isSearchbarOpen,
  ...rest
}) => {
  const [toolbarHeight, setToolbarHeight] = useState<number>(0)
  const offset = 50
  const isSticky = useScrollCondition((scroll) => scroll > offset)
  const isHeaderNoticeEnabled = headerNotice?.text.length > 0

  return (
    <Wrapper toolbarHeight={toolbarHeight} isSticky={isSticky}>
      <ReactHeight onHeightReady={(height) => setToolbarHeight(height)}>
        <MobileToolbar
          {...rest}
          showHeaderPromo={showHeaderPromo}
          headerNotice={headerNotice}
          isHeaderNoticeEnabled={isHeaderNoticeEnabled}
          isSearchbarOpen={isSearchbarOpen}
        />
      </ReactHeight>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  height: ${(props) => props.toolbarHeight}px;
  position: relative;
  z-index: 12;
`

const MobileToolbar: FC<MobileToolbarProps> = ({
  theme,
  onDrawerClick,
  wishlistProductCount,
  showHeaderPromo,
  headerNotice,
  isHeaderNoticeEnabled
}) => {
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false)
  const { getFocusType } = useCurrentPage()
  const { count } = useOrder()
  const isTowerCheckout = useTowerCheckout()
  const display = getFocusType() === FocusMode.Basic ? 'none' : 'inherit'
  const { isLuigiSearchSupported } = useLuigiTracker()
  const { show, showAdditionalMenu, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()
  const { isChristmasPromoTime, isShutDownCountry, isCartProcessStep } =
    useAsset()
  const { isFactcool, isBezvasport } = useContext(StoreContext)
  const { subdomainName } = useDomain()

  const isSearchbarOpen =
    (isFactcool || isBezvasport) &&
    !isCartProcessStep &&
    [MarketCode.Cs, MarketCode.Sk].includes(subdomainName as MarketCode)

  return (
    <StyledFlex pl={12} pr={10} isSearchbarOpen={isSearchbarOpen}>
      <Flex flexDirection="row">
        {!(isCartProcessStep && isTowerCheckout) && (
          <IconButton
            onClick={onDrawerClick}
            title=""
            IconElement={
              <MenuDrawerDefault
                color={theme.colors.background}
                data-cy="mobileHeaderToolbar"
              />
            }
            display={display}
          />
        )}
        <ToolbarLinkLogo ml={display ? 0 : 20} data-cy="headerMobileLogo" />
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        height={29}
        data-cy="mobileHeaderButtons"
      >
        <LoginButton />
        {!isShutDownCountry && (
          <>
            <MobileHeaderButton
              data-cy={Routes.wishlist}
              hrefLink={Routes.wishlist}
              IconElement={
                isChristmasPromoTime ? (
                  <ChristmasGiftBoxIconDefault
                    color={theme.colors.background}
                  />
                ) : (
                  <WishlistIconDefault color={theme.colors.background} />
                )
              }
              notifyCount={wishlistProductCount}
              display={display}
            />
            {!isSearchbarOpen ? (
              <StyledSearchButton
                onClick={() => {
                  setShowSearchInput(true)
                }}
                IconElement={
                  <SearchIconDefault color={theme.colors.background} />
                }
                display={display}
                isTowerCheckout={isTowerCheckout}
                isCartProcessStep={isCartProcessStep}
              />
            ) : null}
            <MobileHeaderButton
              data-cy={Routes.cart}
              hrefLink={Routes.cart}
              IconElement={<CartIconDefault color={theme.colors.background} />}
              notifyCount={count}
            />
            {show && <SlideMenuAdditionalInfo />}
            {showAdditionalMenu || showAdditionalMenuOnHP ? (
              <SwiperMenuAdd color={theme.colors.background} />
            ) : null}
          </>
        )}
      </Flex>
      <MobileSearchWrapper
        show={showSearchInput}
        isHeaderNoticeEnabled={isHeaderNoticeEnabled}
        showHeaderPromo={showHeaderPromo}
        isSearchbarOpen={isSearchbarOpen}
      >
        {isLuigiSearchSupported ? (
          <ProductSearch
            onInputFocusChanged={setShowSearchInput}
            canHideAsPopup={true}
            isVisibleAsPopup={showSearchInput}
            headerNotice={headerNotice}
            showHeaderPromo={showHeaderPromo}
            isSearchbarOpen={isSearchbarOpen}
          />
        ) : (
          <ProductSearchPopUpWrapper
            setShowSearchInput={setShowSearchInput}
            focusOnMount={true}
            isVisibleAsPopup={showSearchInput}
            showHeaderPromo={showHeaderPromo}
            isHeaderNoticeEnabled={isHeaderNoticeEnabled}
          />
        )}
      </MobileSearchWrapper>
    </StyledFlex>
  )
}

export default withTheme(MobileToolbarWrapper)

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.theme.colors.onBackground};
  flex-direction: row;
  justify-content: space-between;
  ${validFor.tablet` 
    background-color: ${(props) => props.theme.colors.background};
  `}
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: ${(props) => props.isSearchbarOpen && '50px'};
  height: 50px;
  transition: all 0.2s ease-out;
`

const StyledSearchButton = styled(IconButtonCapitalized)`
  border: none;
  padding: 10px;
  display: ${(props) =>
    props.isTowerCheckout && props.isCartProcessStep ? 'none' : 'block'};
`

const MobileSearchWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.onBackground};
  justify-content: stretch;
  position: absolute;
  top: ${(props) => props.isSearchbarOpen && '56px'};
  left: 0;
  right: 0;
  margin: 0 10px;
  display: ${(props) =>
    props.show || props.isSearchbarOpen ? 'flex' : 'none'};
`
